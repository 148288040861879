.App {
  margin-left: 5px;
  font-family: 'Roboto Mono', monospace;
}


.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #09d3ac;
}

a:visited, a:link {
  color: black;
  background-color: white;
  text-decoration: none;
}

a:hover, a:active {
  color: white;
  background-color: black;
  text-decoration: none;
}

.HeaderImage {
  width: 220px;
  height: 134.5px;
}

.BigText {
  font-size: 26px;
  font-weight: bold;
}

.section {
  margin-top: 20px;
}

.Player {
  transition: all 3 ease-in;
}

.Mix:hover, .Mix:active {
  color: white;
  background-color: black;
}